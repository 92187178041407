import { get } from 'lodash';
import experiments from './experiments';
import retry, { repeat } from './retry';
import { RELATIVE_API_URL } from '../constants/api';
import { oldBlogAppDefId } from '../constants/apps';
import { NEW_BLOG_QUICK_MIGRATION, NEW_BLOG_MAGIC_MIGRATION } from '../constants/experiments';
import MagicMigration from './magic-migration';

const MIGRATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  CONTENT_SYNC_STARTED: 'CONTENT_SYNC_STARTED',
  CONTENT_SYNC_FAILED: 'CONTENT_SYNC_FAILED',
  CONTENT_SYNC_COMPLETE: 'CONTENT_SYNC_COMPLETE',
  REDIRECTS_SET_COMPLETE: 'REDIRECTS_SET_COMPLETE',
};

const MAGIC_MIGRATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'MAGIC_MIGRATION_STARTED',
  CONTENT_SYNCED: 'MAGIC_MIGRATION_CONTENT_SYNCED',
  FAILED: 'MAGIC_MIGRATION_FAILED',
  SUCCEEDED: 'MAGIC_MIGRATION_SUCCEEDED',
};

const isQuickMigrationEnabled = () => experiments.isEnabled(NEW_BLOG_QUICK_MIGRATION, 'new');
const isMagicMigrationEnabled = () => experiments.isEnabled(NEW_BLOG_MAGIC_MIGRATION, 'new');

const getIsOldBlogInstalled = async (sdk) => {
  const componentsRef = await sdk.document.components.getAllComponents('');

  const componentsData = await Promise.all(
    componentsRef.map((componentRef) => sdk.document.components.data.get('', { componentRef })),
  );

  const oldBlogPageData = componentsData.find((componentData) => get(componentData, 'appPageId') === oldBlogAppDefId);

  return Boolean(oldBlogPageData);
};

const shouldMigrateOldBlog = async ({ sdk, isADI }) => {
  if (isADI) {
    return false;
  }

  await Promise.all([
    experiments.conductSingle(NEW_BLOG_QUICK_MIGRATION, 'old'),
    experiments.conductSingle(NEW_BLOG_MAGIC_MIGRATION, 'old'),
  ]);

  const canMigrate = isQuickMigrationEnabled() || isMagicMigrationEnabled();

  if (!canMigrate) {
    return false;
  }

  const isOldBlogInstalled = await getIsOldBlogInstalled(sdk);
  if (!isOldBlogInstalled) {
    return false;
  }

  return true;
};

const getContentMigrationApiUrl = () => `${RELATIVE_API_URL}/_api/content-migration`;

const startMigration = ({ instance, setRedirects = true } = {}) => {
  const requestUrl = isMagicMigrationEnabled()
    ? `${RELATIVE_API_URL}/_api/magic-migration/sync-content?viewMode=editor`
    : `${getContentMigrationApiUrl()}/transfer-content?viewMode=editor&redirectOnTransferCompletion=${setRedirects}`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', instance },
  })
    .then((res) => {
      if (res.status === 404) {
        throw new Error(`Blog not found: ${instance}`);
      }
      return res.ok;
    })
    .catch((err) => {
      throw err;
    });
};

const getMigrationStatus = (instance) =>
  fetch(`${getContentMigrationApiUrl()}/details?viewMode=editor`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', instance },
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then(({ status }) => status);

const markMigrationSuccess = async (instance) =>
  fetch(`${RELATIVE_API_URL}/_api/magic-migration/mark-succeeded?viewMode=editor`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', instance },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });

const markMigrationFailure = async (instance) =>
  fetch(`${RELATIVE_API_URL}/_api/magic-migration/mark-failed?viewMode=editor`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', instance },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });

const checkMigrationStatus = (instance) => async (stopChecking) => {
  const status = await retry(() => getMigrationStatus(instance), 3);

  if (
    isMagicMigrationEnabled() &&
    [MAGIC_MIGRATION_STATUS.CONTENT_SYNCED, MAGIC_MIGRATION_STATUS.FAILED, MAGIC_MIGRATION_STATUS.SUCCEEDED].includes(
      status,
    )
  ) {
    stopChecking();
  } else if ([MIGRATION_STATUS.CONTENT_SYNC_FAILED, MIGRATION_STATUS.REDIRECTS_SET_COMPLETE].includes(status)) {
    stopChecking();
  }

  return status;
};

const migrateOldBlog = async (context) => {
  return new Promise(async (resolve, reject) => {
    const instance = await context.sdk.document.info.getAppInstance('');

    let status;
    let error = null;
    try {
      await retry(() => startMigration({ instance, setRedirects: !isMagicMigrationEnabled() }), 6, 5000);

      const delay = 15000;
      const times = 80;
      status = await repeat(checkMigrationStatus(instance), times, delay);
    } catch (_) {}

    if (
      isMagicMigrationEnabled() &&
      (status === MAGIC_MIGRATION_STATUS.CONTENT_SYNCED || status === MAGIC_MIGRATION_STATUS.SUCCEEDED)
    ) {
      try {
        await new MagicMigration(context).run();
        await retry(() => markMigrationSuccess(instance), 3);
      } catch (err) {
        error = err;
      }

      if (error) {
        try {
          await retry(() => markMigrationFailure(instance), 3);
        } catch (_) {}
      }
    }

    error ? reject(error) : resolve(status);
  });
};

export default { shouldMigrate: shouldMigrateOldBlog, migrate: migrateOldBlog };
