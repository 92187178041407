import guidUtils from '@wix/santa-core-utils/dist/cjs/coreUtils/core/guidUtils';
import { TOKEN } from './constants';
import { blogAppDefId } from '../../constants/apps';

export const getAllComponents = async (sdk) => {
  const allComponents = await sdk.components.getAllComponents(TOKEN);
  return Promise.all(
    allComponents.map((componentRef) =>
      Promise.all([
        sdk.components.getType(TOKEN, { componentRef }),
        sdk.components.getAncestors(TOKEN, { componentRef }),
      ]).then(([type, ancestors]) => ({
        type,
        componentRef,
        containerRef: ancestors[0],
        pageRef: ancestors[ancestors.length - 1],
      })),
    ),
  );
};

export const getSerializedComponents = async ({ sdk, predicate = () => true, maintainIdentifiers = true }) => {
  const components = (await getAllComponents(sdk)).filter(predicate);

  return Promise.all(
    components.map(({ componentRef, ...rest }) =>
      sdk.document.components
        .serialize(TOKEN, { componentRef, maintainIdentifiers })
        .then((serialized) => ({ serialized, componentRef, ...rest })),
    ),
  );
};

const getUniqueSectionId = (section) => async (sdk) => {
  for (let i = 0; i < 10; i++) {
    const sectionId = `${section}_${guidUtils.getUniqueId()}`;
    const isSectionIdTaken = Boolean(await sdk.document.components.getById(TOKEN, { id: sectionId }));
    if (!isSectionIdTaken) {
      return sectionId;
    }
  }

  throw new Error('cannot generate an unique section id');
};

export const getUniqueSectionIdForFeedPage = getUniqueSectionId('TPASection');
export const getUniqueSectionIdForPostPage = getUniqueSectionId('TPAMultiSection');

export const getComponnetRef = async (sdk, widgetId) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(TOKEN, blogAppDefId);
  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(TOKEN, blogAppData.applicationId);
  const component = blogAppComponents.find((_component) => _component.widgetId === widgetId);
  const componentRef = await sdk.document.components.getById(TOKEN, { id: component.id });
  return componentRef;
};

export const getCoordinatesRelativeToStructure = async (sdk, componentRef) => {
  const ancestors = await sdk.components.getAncestors(TOKEN, { componentRef });
  const layouts = await Promise.all(
    [componentRef, ...ancestors].map((ancestor) => sdk.components.layout.get(TOKEN, { componentRef: ancestor })),
  );
  const coordinates = layouts.reduce(
    (result, { x, y }) => {
      result.x += x;
      result.y += y;
      return result;
    },
    { x: 0, y: 0 },
  );

  return coordinates;
};
